<template>

    <div class="wrapContentIndex wrapContentDepositWithdraw">
        <div class="DepositWithdraw">
            <div class="contentOverlay contentOverlaySidebar" :class="{'popupProvider': isHideProvider}">
                <div class="btn-close-popupAllgame" @click="ClosePopupProvider()"></div>
                <div class="contentListProviderLeft menuStyle">
                    <div class="contentListProviderLeftInfo">
                        <h3>เมนู</h3>
                        <MenuLeft :is_slidebar="true"></MenuLeft>
                    </div>
                </div>
            </div>
            <FloatButtomRightPanel  :callChatWithAdmin="showChatWithAdminNumber"/>
            <div class="wrapContentIndexTitle">
                <button class="btn " @click="goHome()"><i class="fas fa-chevron-left"></i></button>
                <div class="bx-mobile" v-if="false">
                    <div class="hamburger-lines" @click="OpenListProvider()">
                        <span class="line line1"></span>
                        <span class="line line2"></span>
                        <span class="line line3"></span>
                    </div>
                </div>
                <h3>ฝาก - ถอน</h3>
            </div>
            <div class="navTopDesktop">
                <MenuNavTop @showMenuNav="showMenuNav"  @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"></MenuNavTop>
            </div>
            <MenuBar :callMenu="menuNumber" :callDWMenu="showDepositWithdrawNumber"></MenuBar>

            <!--                <MenuBarUnbox></MenuBarUnbox>-->
            <!--        <MenuBarSub title="ฝาก-ถอน"></MenuBarSub>-->
            <div class="box-img hide">
                <!--            <div class="box-user-img"><img src="@/assets/images/icon/set-icon/icon-acc-manage.png"/></div>-->
                <div class="box-user-detail">
                    <strong>ฝาก-ถอน</strong>
                    <!--                <small>Deposit Withdraw</small>-->
                </div>
            </div>
            <div class="wrapContentIndexInfo">
                <div class="contentFlexIndex">
                    <div class="contentFlexIndexLeft active">
                        <MenuLeft></MenuLeft>
                    </div>
                    <div class="contentFlexIndexRight">
                        <div class="container">
                            <div class="content-page">
                                <div class="content-page-spin">

                                    <div class="container-fluid-game container-fluid-game-spin">
                                        <UserDepositWithdraw></UserDepositWithdraw>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="navButtonMobile">
                    <MenuNav  @showMenuNav="showMenuNav"  @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"></MenuNav>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    import FloatButtomRightPanel from '@/components/FloatButtomRightPanel'
    import MenuBar from '@/components/MenuBar'
    import UserDepositWithdraw from '@/components/user/DepositWithdraw'

    import MenuLeft from '@/components/MenuLeft'
    import MenuNav from '@/components/MenuNav'
    import MenuNavTop from '@/components/MenuNavTop'

// import MenuBarSub from '@/components/menu-bar/Sub'
//     import MenuBarUnbox from '@/components/menu-bar/Unbox'


export default {
    name: 'DepositWithdraw',
    components: {
        FloatButtomRightPanel,
        UserDepositWithdraw,
         MenuBar,
        MenuLeft,
        MenuNav,
        MenuNavTop,

        // MenuBarSub,
        // MenuBarUnbox,
    },
    data() {
        return {
            showChatWithAdminNumber:0,
            showDepositWithdrawNumber:0,
            menuNumber :0,
            isHideProvider: false,
            isHideListGame: true,
            isHideListGameContent: false,
        }
    },
    methods: {
        goHome() {
            this.$router.push({name: 'Index'})
        },
        showChatWithAdmin(){
                this.showChatWithAdminNumber=1;
                let self =this;
                setTimeout(()=>{
                    self.showChatWithAdminNumber=0;
                },100);
                
        },
        showDepositWithdrawModal(){
                this.showDepositWithdrawNumber=1;
                let self =this;
                setTimeout(()=>{
                    self.showDepositWithdrawNumber=0;
                },100);
                
        },

        showMenuNav(menuNumber){
                this.menuNumber = menuNumber;
                let self =this;
                setTimeout(()=>{
                    self.menuNumber=0;
                },1000);
            },
        OpenListProvider() {
            this.isHideProvider = true;
        },
        ClosePopupProvider() {
            this.isHideProvider = false;
        },
        OpenListAllGame(){
            this.isHideListGame = false;
            this.isHideListGameContent = true;
        },
        CloseListAllGame() {
            this.isHideListGame = true;
            this.isHideListGameContent = false;
        },

        toIndexPopularPage() {
            this.$router.push({name: 'IndexPopular'})
        },
        toIndexCasinoPage() {
            this.$router.push({name: 'IndexCasino'})
        },
        toIndexSlotPage() {
            this.$router.push({name: 'IndexSlot'})
        },
        toIndexFishingPage() {
            this.$router.push({name: 'IndexFishing'})
        },
        toIndexSportPage() {
            this.$router.push({name: 'IndexSport'})
        },
        toIndexLottoPage() {
            this.$router.push({name: 'IndexLotto'})
        },
        toIndexUnboxPage() {
            this.$router.push({name: 'IndexUnbox'})
        },
    }
}
</script>
<style src="@/assets/css/unbox.css" scoped></style>
<style>
    /*.DepositWithdraw .MenuBar.box-all-games{display: none!important}*/
    /*.DepositWithdraw .menu-list-top .notiBalance{display: none}*/
    /*.DepositWithdraw .menu-list-top span:nth-child(3){display: none!important}*/
    /*.DepositWithdraw .box-balance-total-info span{display: none!important}*/
    /*.DepositWithdraw .box-advertise-game-bottom {bottom: 65px;}*/
    .DepositWithdraw span.box-txt-back:before{
        content: '\e88a';
        font: 25px "Material Icons";
        margin-right: 5px;
    }

    .DepositWithdraw .box-img{
        position: absolute;
        height: 45px!important;
        top: 0;
        font: 800 20px/1 Athiti;
        color: white;
        left: 50px;
        margin: auto;
        display: flex;
        right: 50px;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }
    @media (orientation: landscape) {
        /*.DepositWithdraw .MenuBar.box-all-games{display: block!important}*/
    }
</style>
<style scoped>
    .DepositWithdraw .content-page-spin{position: relative}
    .DepositWithdraw .box-img{
        /*top: 4px!important;*/
        /*left: 45px!important;*/
        /*padding: 0!important;*/
    }

    /*.box-user-detail{width: 150px;}*/
    /*.DepositWithdraw .container-fluid-game-spin{overflow-x: hidden;height: calc(100vh - 116px);}*/
    .DepositWithdraw .container-fluid-game-spin{overflow-x: auto;height: 100%}
    .DepositWithdraw .page-content {
        height: calc(100vh - 50px);
        overflow-y: auto;
    }
    .wrap-menu-top{background-image: none;}
    .DepositWithdraw{
        background-image: linear-gradient(
            135deg
            , #F372D4 10%, #c765f0 100%);
}
    .DepositWithdraw .content-page{
        /*background-color: #f1f5fd;*/
        padding: 0;
        height: initial;
        overflow-y: initial;
        z-index: 1;
        position: relative;
    }
    .content-page {background-color: #fff;border-radius: 20px 20px 0 0;padding: 5px 0;}
    .box-user-img,
    .box-user-img img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 10px;
    }
    .box-user-detail{padding-left: 10px;}
    .box-user-img{display: none;}
    .box-img, .box-menu-img {display: flex;font-family: "Athiti", sans-serif;}

.box-img small {display: block;margin-top: -5px;}
</style>